.App {
  text-align: center;
  font-family: "AvenirMedium";
  text-transform: uppercase;
}

h1{
  color: #101010;
}

h2{
  color: #101010;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  font-size: 20px;
}

h3{
  color: #101010;
}

h4{
  color: #101010;
}

h5{
  color: #101010;
}

h6{
  color: #101010;
}

.header {
  background-color: #5A51A2;
  margin-bottom: 48px;
}

.header-img {
  height: 70px;
  width: 100%;
  object-fit: contain;
  background-color: #00A9C1;
}

.header-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  width: 60%;
  font-size: 20px;
}

.header-sondeo {
  background-color: #1C1B22;
  margin-bottom: 48px;
}

.header-img-sondeo {
  height: 70px;
  width: 100%;
  object-fit: contain;
  background-color: #1C1B22;
}

.header-text-sondeo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  width: 60%;
  font-size: 20px;
}

.header-qp {
  background: rgb(0,84,168);
  background: -moz-linear-gradient(90deg, rgba(0,84,168,1) 40%, rgba(0,130,194,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,84,168,1) 40%, rgba(0,130,194,1) 100%);
  background: linear-gradient(90deg, rgba(0,84,168,1) 40%, rgba(0,130,194,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0054a8",endColorstr="#0082c2",GradientType=1);
  margin-bottom: 48px;
}

.header-img-qp {
  height: 70px;
  width: 100%;
  object-fit: contain;
  background-color: #000;
}

.header-text-qp {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  width: 77%;
  font-size: 20px;
  font-family: "MonserratMedium";
}

.question-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #101010;
  margin-top: 20px;
  margin-bottom: 32px;
}

.question-text-sondeo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #101010;
  margin-top: 20px;
  margin-bottom: 32px;
  font-family: "AvenirBlack";
  font-size: 24px;
  max-width: 800px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

.question-text-dv {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #101010;
  margin-top: 20px;
  margin-bottom: 32px;
  font-family: "AvenirMedium";
  font-size: 24px;
  max-width: 800px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

.question-text-qp {
  display: flex;
  justify-content: center;
  align-items: center;
  color: dimgray;
  margin-top: 20px;
  margin-bottom: 32px;
  font-family: "MonserratSemiBold";
  max-width: 800px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}

.question-text-qp h2{
  font-size: 28px;
  color: #565656;
}

.question-text-sondeo h2{
  font-size: 28px;
}

.photo-card {
  background-color: #F2F3F8;
  border-radius: 16px;
  height: 100%;
  width: 100%;
}

.photo-card-qp {
  background-color: #dad8f4;
  border-radius: 16px;
  height: 100%;
  width: 100%;
}

.photo-card-img {
  height: 64%;
  width: 80%;
  margin-top: 8%;
  border-radius: 16px;
  object-fit: cover;
}

.photo-card-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28%;
  margin-left: 8%;
  margin-right: 8%;
  color: #101010;
}

.photo-card-text-sondeo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28%;
  margin-left: 8%;
  margin-right: 8%;
  color: #101010;
}

.photo-card-text-qp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28%;
  margin-left: 8%;
  margin-right: 8%;
  color: #101010;
  font-family: "MonserratMedium";
}

.photo-card-big {
  background-color: #F2F3F8;
  border-radius: 16px;
  height: 90%;
  width: 100%;
  justify-self: center;
  max-height: 440px;
}

.photo-card-img-big {
  height: 100%;
  width: 80%;
  padding-top: 8%;
  padding-bottom: 8%;
  object-fit: cover;
  border-radius: 16px;
}
.yes-no {
  width:100%;
  display: flex;
  justify-content: center;
}

.yes-no-text {
  background-color: #F2F3F8;
  margin-top: 48px;
  margin-bottom: 48px;
  color: #101010;
  width: 240px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 25px;
}

.yes-no-text-qp {
  background-color: #F2F3F8;
  margin-top: 48px;
  margin-bottom: 48px;
  color: #101010;
  width: 240px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 25px;
}

.yes-no-text-qp h2{
  font-family: "MonserratMedium";
}

.footer {
  width:100%;
  display: flex;
  justify-content: center;
}

.footer-text {
  background-color: #F2F3F8;
  margin-top: 48px;
  margin-bottom: 48px;
  color: #101010;
  width: 240px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 25px;
}

.footer-text-sondeo {
  background-color: #F2F3F8;
  margin-top: 48px;
  margin-bottom: 48px;
  color: #101010;
  width: 240px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 25px;
}

.footer-text-qp {
  background-color: #F2F3F8;
  margin-top: 48px;
  margin-bottom: 48px;
  color: #101010;
  width: 240px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 25px;
}

.footer-text-qp h2{
  font-family: "MonserratMedium";
}

.yes-no-footer {
  width:100%;
  display: flex;
  justify-content: center;
  visibility: hidden;
}

.yes-no-footer-text {
  background-color: #F2F3F8;
  margin-top: 0px;
  margin-bottom: 48px;
  color: #101010;
  width: 240px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 25px;
}

.end-poll {
  width:40%;
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.end-poll-text-dv {
  margin-top: 15%;
  color: #5A51A2;;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.end-poll-text-sondeo {
  margin-top: 15%;
  color: #101010;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.end-poll-text-qp {
  margin-top: 15%;
  color: dimgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: "MonserratMedium";
}

.end-poll-img {
  height: 50%;
  width: 50%;
  padding-top: 8%;
  padding-bottom: 8%;
  object-fit: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.font-face-global {
  font-family: "AvenirMedium";
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.div-question-sondeo {
  display: inline-block;
  width: 100%;
}

.div-question-qp {
  display: inline-block;
  width: 100%;
}

.div-question-dv {
  display: inline-block;
  width: 100%;
}

.div-question-container {
  width: 80%;
  display: flex;
  align-items: center;
  margin: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 721px) {
  div.photo-card-text-sondeo {
    font-size: 1.2vw;
  }
  div.photo-card-text-qp {
    font-size: 1.2vw;
  }
  div.photo-card-text-sondeo {
    font-size: 1.2vw;
  }
}

@media screen and (max-width: 720px) {
  div.photo-card-text-sondeo {
    font-size: 2.2vw;
  }
  div.photo-card-text-qp {
    font-size: 2.2vw;
  }
  div.photo-card-text-sondeo {
    font-size: 2.2vw;
  }
}